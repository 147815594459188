<script setup lang="ts">
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { usePromoCarousel } from '#imports';

import 'swiper/css';
import 'swiper/css/pagination';

const modules = [Pagination, Autoplay];

const { promoCarouselData, swiperReady, getPromoPath, onClickCarouselItem, onSlideCarousel } = usePromoCarousel();
</script>

<template>
  <div class="w-full">
    <h2 class="text-gray-darkest font-bold px-15 pb-15">
      {{ $t('hotel.unbeatableoffer') }}
    </h2>

    <Swiper
      :autoplay="{
        delay: 5000,
      }"
      :pagination="{ el: '.swiper-pagination', clickable: true }"
      :class="['w-full max-h-[267px] aspect-[15/8]', promoCarouselData?.length === 1 && '!px-15']"
      lazy-preloader-class="placeholder"
      :modules="modules"
      slides-per-view="auto"
      :centered-slides="promoCarouselData?.length === 1"
      @after-init="swiperReady = true"
      @touch-end="onSlideCarousel"
    >
      <SwiperSlide
        v-for="(item, index) in promoCarouselData"
        :key="index"
        class="relative ml-10 first:ml-15 last:mr-15"
        :class="['relative !min-h-[80%]', promoCarouselData?.length === 1 ? '!w-full' : '!w-[80%] !h-[85%]']"
        @click="onClickCarouselItem(item, index)"
      >
        <NuxtLink
          :to="getPromoPath(item)"
          external
          no-rel
          aria-label="Link to Promo Page or Apps Download"
        >
          <NuxtImg
            :src="item.img"
            :alt="item.img"
            :preload="index === 0"
            :loading="index === 0 ? 'eager' : 'lazy'"
            :fetchpriority="index === 0 ? 'high' : 'low'"
            width="230"
            height="123"
            format="webp"
            fit="scale-down"
            sizes="lg:70vw"
            class="absolute w-full h-full rounded"
          />
        </NuxtLink>

        <NuxtImg
          v-if="index > 0"
          class="absolute placeholder w-full h-full"
          src="/img/placeholder.png"
        />
      </SwiperSlide>

      <div class="swiper-pagination absolute flex gap-x-5 w-full justify-center items-center bottom-10" />
    </Swiper>
  </div>
</template>

<style scoped lang="scss">
.swiper:not(.swiper-initialized) {
  .swiper-slide + .swiper-slide {
    @apply mr-10;
  }

  .swiper-wrapper {
    @apply translate-x-15;
  }
}

.swiper-pagination {
  :deep(.swiper-pagination-bullet) {
    @apply w-[5px] h-[5px] bg-gray-dark m-0;

    &.swiper-pagination-bullet-active {
      @apply scale-150 bg-opacity-100;
    }
  }
}
</style>
